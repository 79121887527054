import DASHBOARDS, { dashboardTitles } from 'assets/constants/dashboards';
import dateRange from 'assets/constants/date-range';
import fieldLabel from 'assets/constants/fieldLabel';
import mlsStatus from 'assets/constants/mls-status';
import sellerResponse from 'assets/constants/seller-response';
import DefaultDashboardFields from 'assets/list/dashboard/default-column';
import AutoCompleteUnit from 'components/form/auto-complete-unit';
import ClearButton from 'components/form/button-clear';
import RefreshButton from 'components/form/button-refresh';
import SearchButton from 'components/form/button-search';
import FormContainer from 'components/form/container';
import UnitAccount from 'components/form/unit-account';
import UnitBrokerageUser from 'components/form/unit-brokerage-user';
import UnitDate from 'components/form/unit-date';
import UnitItem from 'components/form/unit-item';
import UnitMsa from 'components/form/unit-msa';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRow from 'components/stack/stack-row';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import React, { useEffect, useState } from 'react';
import userPreferenceService from 'services/user-preference-service';
import dashboardInitialFilters from 'state/dashboard';
import { ObjectType } from 'types';
import { DispatchSetState, InputChangeEvent } from 'types/common-types';
import { DashboardFilterEntity } from 'types/dashboard-type';
import { OptionType } from 'types/option-type';

import ColumnChooser from './column-chooser';

interface FilterProps {
  page: number;
  setIsFilterChanged: DispatchSetState<boolean>;
  filter: DashboardFilterEntity;
  setFilter: DispatchSetState<DashboardFilterEntity>;
  updateFilter: (value: InputChangeEvent) => void;
  updatePagiantion: (value: ObjectType) => void;
  dashboard: string;
}

const Filters = (props: FilterProps) => {
  const {
    page,
    setIsFilterChanged,
    filter,
    setFilter,
    updateFilter,
    updatePagiantion,
    dashboard
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changeFilter = (obj: InputChangeEvent) => {
    updateFilter(obj);
  };

  const saveFilters = async (value: DashboardFilterEntity) => {
    setFilter(value);
    setIsFilterChanged(true);

    setIsLoading(true);

    await userPreferenceService.save({
      category: dashboard,
      subcategory: DASHBOARDS.FILTER,
      contents: value
    });

    setIsLoading(false);
  };

  const loadFilter = async () => {
    const response = await userPreferenceService.getItem<DashboardFilterEntity>(
      {
        category: dashboard,
        subcategory: DASHBOARDS.FILTER,
        module: fieldLabel.opportunities
      }
    );

    if (response.isSuccess && response.data) {
      setFilter(response.data.contents);
      setIsFilterChanged(true);
    }
  };

  useEffect(() => {
    loadFilter();
  }, []);

  return (
    <PaperBox>
      <PaperBoxContent>
        <FormContainer>
          <UnitText
            name={'property_address_c'}
            label={fieldLabel.propertyAddress}
            value={filter.property_address_c}
            onChange={changeFilter}
            grid={{ xs: 12, sm: 4 }}
          />
          <UnitText
            name={'entera_opportunity_id'}
            label={fieldLabel.enteraOpportunitId}
            value={filter.entera_opportunity_id}
            onChange={changeFilter}
            grid={{ xs: 12, sm: 4 }}
          />
          <UnitText
            name={'mls_c'}
            label={fieldLabel.mls}
            value={filter.mls_c}
            onChange={changeFilter}
            grid={{ xs: 12, sm: 4 }}
          />
          <UnitText
            name={'street_name'}
            label={fieldLabel.streetName}
            value={filter.street_name}
            onChange={changeFilter}
            grid={{ xs: 12, sm: 4 }}
          />
          <UnitAccount
            value={filter.account_id || []}
            multiple={true}
            onChange={(val: OptionType[]) => {
              updateFilter({
                target: {
                  name: 'account_id',
                  value: val
                }
              });
            }}
            grid={{ xs: 12, sm: 4 }}
          />
          <UnitMsa
            value={filter.msa_id}
            multiple={true}
            onChange={(val: OptionType[]) => {
              updateFilter({
                target: {
                  name: 'msa_id',
                  value: val
                }
              });
            }}
            grid={{ xs: 12, sm: 4 }}
          />
          <AutoCompleteUnit
            label={fieldLabel.opportunityStatus}
            id="opportunity_status_c"
            options={dashboardInitialFilters[dashboard].opportunity_status_c}
            multiple={true}
            values={filter.opportunity_status_c}
            grid={{ xs: 12, sm: 4 }}
            onChange={(e: InputChangeEvent) => {
              if (e.target.value.length > 0)
                changeFilter({
                  target: {
                    name: 'opportunity_status_c',
                    value: e.target.value
                  }
                });
            }}
          />

          <AutoCompleteUnit
            label={fieldLabel.mlsStatus}
            id="mls_status_c"
            options={getObjectEntriesAsArray(mlsStatus)}
            multiple={false}
            values={filter.mls_status_c}
            grid={{ xs: 12, sm: 4 }}
            onChange={(e: InputChangeEvent) => {
              changeFilter({
                target: {
                  name: 'mls_status_c',
                  value: e.target.value
                }
              });
            }}
          />

          <AutoCompleteUnit
            label={fieldLabel.sellerResponse}
            id="seller_offer_response"
            options={getObjectEntriesAsArray(sellerResponse)}
            multiple={false}
            values={filter.seller_offer_response}
            grid={{ xs: 12, sm: 4 }}
            onChange={(e: InputChangeEvent) => {
              changeFilter({
                target: {
                  name: 'seller_offer_response',
                  value: e.target.value
                }
              });
            }}
          />

          <UnitBrokerageUser
            label={fieldLabel.primaryNegotiator}
            value={filter.active_primary_negotiator_user_id}
            multiple={true}
            onChange={(val: OptionType[]) => {
              updateFilter({
                target: {
                  name: 'active_primary_negotiator_user_id',
                  value: val
                }
              });
            }}
            grid={{ xs: 12, sm: 4 }}
          />

          <UnitItem grid={{ xs: 12, sm: 4 }}>
            <FormContainer spacing={1}>
              <UnitSelect
                name="offer_date_c_range"
                label={fieldLabel.dateRange}
                records={getObjectEntriesAsArray(dateRange)}
                value={filter.offer_date_c_range ?? ''}
                onChange={changeFilter}
                readOnly={false}
                grid={{ xs: 12, sm: 4 }}
              />
              {filter.offer_date_c_range === 'date-range' ? (
                <>
                  <UnitDate
                    label={fieldLabel.offerDate}
                    name="offer_date_c"
                    value={filter.offer_date_c ?? ''}
                    onChange={(e: string) => {
                      changeFilter({
                        target: {
                          name: 'offer_date_c',
                          value: e
                        }
                      });
                    }}
                    grid={{ xs: 12, sm: 4 }}
                  />

                  <UnitDate
                    label={fieldLabel.offerToDate}
                    name="offer_date_to_c"
                    value={filter.offer_date_to_c ?? ''}
                    onChange={(e: string) => {
                      changeFilter({
                        target: {
                          name: 'offer_date_to_c',
                          value: e
                        }
                      });
                    }}
                    grid={{ xs: 12, sm: 4 }}
                  />
                </>
              ) : (
                <UnitDate
                  label={fieldLabel.offerDate}
                  name="offer_date_c"
                  value={filter.offer_date_c ?? ''}
                  onChange={(e: string) => {
                    changeFilter({
                      target: {
                        name: 'offer_date_c',
                        value: e
                      }
                    });
                  }}
                  grid={{ xs: 12, sm: 8 }}
                />
              )}
            </FormContainer>
          </UnitItem>
        </FormContainer>

        <StackRow sx={{ pt: 2, pr: 0, pb: 0, pl: 0 }}>
          <SearchButton
            onClick={() => saveFilters(filter)}
            disabled={isLoading}
          />{' '}
          <ClearButton
            onClick={() => {
              saveFilters(dashboardInitialFilters[dashboard]);
            }}
          />
          <ColumnChooser
            title={dashboardTitles[dashboard]}
            dashboard={dashboard}
            defaultColumns={DefaultDashboardFields[dashboard]}
          />
          <RefreshButton
            onClick={() => {
              if (page !== 0) {
                updatePagiantion({ page: 0 });
              } else {
                setIsFilterChanged(true);
              }
            }}
          />
        </StackRow>
      </PaperBoxContent>
    </PaperBox>
  );
};

export default Filters;
